import { Autocomplete, TextField, styled } from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import CustomTextField from "../../../Layout/Components/TextField";
import { fontWeight } from "@mui/system";
import moment from "moment";
import { TextArea } from "@progress/kendo-react-inputs";
import { dataCsvIcon } from "@progress/kendo-svg-icons";
import EmployeeSignature from "../../../CommonComponents/EmployeeSignature";
import {
  getTransaction,
  getinfosalaryIncrement,
} from "../../../store/salaryIncrement/action";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDataBySSNAndAction } from "../NPAForms/slices/getDataBySSNSlice";
import * as url from "../../../_helper/url_helper";
import { post } from "../../../_helper/api_helper";
// import GuamSeal from "../../../../public/GuamSeal.png"
const B = styled("b")(({ theme }) => ({
  fontSize: "14px",
}));

const NpaPdf = React.forwardRef((props, ref) => {
  //   const { data, printState } = props;
  //   console.log("11111 directNpa", data);
  //   const CheckValue = (temp) => {
  //     if (temp !== null && temp !== undefined && temp !== "") {
  //       return true;
  //     }
  //     return false;
  //   };

  const { printState } = props;
  const [data, setData] = useState({});

  const { employeeID, hrControl } = useParams();

  const dispatch = useDispatch();

  //   const [signatureData, setSignatureData] = useState({});

  const baseURL = process.env.REACT_APP_API_DOTNET_URL;
  const imagePath = process.env.REACT_APP_PUBLIC_URL;

  useEffect(() => {
    const url1 = `${baseURL}${url.NPADOWNLOAD}?hrControl=${hrControl}&employeeID=${employeeID}`;
    const payload = { employeeID, hrControl };

    post(url1, payload)
      .then((data) => {
        setData(data);
        console.log("Response:", data);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          // backgroundColor: "#ccfff9",
          padding: "20px",
          fontSize: "12px",
          fontWeight: "bold",
          lineHeight: "1.5",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 !important",
            fontWeight: "bold",
            lineHeight: "1.3",
          }}
        >
          <Col
            sm={2}
            className="p-1"
            style={{
              fontSize: "10px",
              display: "flex",
              justifyContent: "start",
              alignItems: "end",
            }}
          ></Col>
          <Col sm={8} className="text-center" style={{ fontSize: "14px" }}>
            GOVERNMENT OF GUAM <br />
            AGANA, GUAM <br />
            NOTIFICATION OF PERSONNEL ACTION
          </Col>
          <Col sm={2}></Col>
        </Row>

        <Row style={{ borderTop: "1px solid black" }}>
          <Col sm={6} className="p-1 border-dark border-end">
            1. Fullname
            <br />
            <span className="p-4">{data?.employeeNameLfm}</span>
          </Col>

          <Col sm={2} className="p-1 border-dark border-end">
            2. Date of Birth <br />
            {printState == "Print NPA Redacted" ? (
              <span
                className="m-1"
                style={{ height: "25px", backgroundColor: "#000" }}
              >
                XX XXX XXXX
              </span>
            ) : (
              <span className="p-4">
                {data?.ndateOfBirth &&
                  moment(data?.ndateOfBirth).format("DD MMM YYYY")}
              </span>
            )}
          </Col>
          <Col sm={2} className="p-1 border-dark border-end">
            3.Employee ID
            <br />
            <span className="p-4">
              {data && data.employeeId ? data.employeeId : ""}
            </span>
          </Col>

          <Col sm={2} className="p-1 border-dark">
            4.Date Requested <br />
            <span className="p-4"> {moment().format("DD MMM YYYY")}</span>
          </Col>
        </Row>
        <Row style={{ borderTop: "1px solid black" }}>
          <Col sm={12} className="p-1 border-dark text-center">
            THIS IS TO NOTIFY YOU OF THE FOLLOWING ACTION AFFECTING YOUR
            EMPLOYMENT
          </Col>
        </Row>
        <Row style={{ borderTop: "1px solid black" }}>
          <Col sm={5} className="p-1 border-dark border-end">
            5.Nature of Action <br />
            <span className="p-4">{data?.natureTitle} </span>
          </Col>
          <Col sm={3} className="p-1 border-dark border-end">
            6.Effective Date <br />
            <span className="p-4">
              {" "}
              {data?.actnEffDate &&
                moment(data.actnEffDate).format("DD MMM YYYY")}
            </span>
          </Col>
          <Col sm={4} className="p-1 border-dark">
            7.Legal Authority <br />
            <span className="p-4">{data?.legalAuthority} </span>
          </Col>
        </Row>
        <Row style={{ borderTop: "1px solid black" }}>
          <Col sm={5} className="p-1 border-dark border-end text-center">
            FROM
          </Col>
          <Col sm={2} className="p-1 border-dark border-end text-center">
            Description
          </Col>
          <Col sm={5} className="p-1 text-center">
            TO
          </Col>
        </Row>
        <Row style={{ borderTop: "1px solid black" }}>
          <Col sm={5} className="p-1 border-dark border-end text-center p-3">
            <Row>
              <Col sm={12} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="FR_Title"
                  label="Position"
                  value={data?.fromPositionTitle?.trim()}
                  shrink={true}
                />
              </Col>
              <Col sm={4} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="fromPayGrade"
                  label="Pay Grade"
                  value={data?.fromPayGrade?.trim()}
                  shrink={true}
                />
              </Col>
              <Col sm={8} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="fromSalary"
                  label="Salary Per Annum"
                  value={data?.fromSalary}
                  shrink={true}
                />
              </Col>
              <Col sm={4} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="fromStep"
                  label="Step"
                  value={data?.fromStep}
                  shrink={true}
                />
              </Col>
              <Col sm={8} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="fromRate"
                  label="Rate per Hour"
                  value={data?.fromRate}
                  shrink={true}
                />
              </Col>
              <Col sm={12} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="department"
                  label="Department"
                  value={data?.fromDepartment}
                  shrink={true}
                />
              </Col>
              <Col sm={12} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="section_divison"
                  label="Section/Division"
                  value={data?.fromDeptDivSec}
                  shrink={true}
                />
              </Col>
              <Col sm={12} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="FR_FTE_Number"
                  label="FTE"
                  value={data?.fromFteNo}
                  shrink={true}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={2} className="p-1 border-dark border-end pt-3">
            <Row>
              <Col
                sm={12}
                style={{
                  minHeight: "46.25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                8. Position Title
              </Col>
              <Col
                sm={12}
                style={{
                  minHeight: "46.25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                {" "}
                9. Pay Grade/Step Salary Rate
              </Col>
              <Col
                sm={12}
                style={{
                  minHeight: "46.25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              ></Col>
              <Col
                sm={12}
                style={{
                  minHeight: "46.25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                {" "}
                10. Department
              </Col>
              <Col
                sm={12}
                style={{
                  minHeight: "46.25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                {" "}
                11. Div./Section
              </Col>

              <Col
                sm={12}
                style={{
                  minHeight: "46.25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                12.Position Number
              </Col>
            </Row>
          </Col>
          <Col sm={5} className="p-1  text-center p-3">
            <Row>
              <Col sm={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="to_title"
                  label="Position"
                  value={data?.toPositionTitle?.trim()}
                  shrink={true}
                />
              </Col>
              <Col sm={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="to_pay_grade"
                  label="Pay Grade"
                  value={data?.toPayGrade?.trim()}
                  shrink={true}
                />
              </Col>
              <Col sm={8}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="toSalary"
                  label="Salary"
                  value={data?.toSalary}
                  shrink={true}
                />
              </Col>
              <Col sm={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="to_strp"
                  label="Step"
                  value={data?.toStep}
                  shrink={true}
                />
              </Col>
              <Col sm={8}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="toRate"
                  label="Rate"
                  value={data?.toRate}
                  shrink={true}
                />
              </Col>
              <Col sm={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="toDepartment"
                  label="Department"
                  value={data?.toDepartment}
                  shrink={true}
                />
              </Col>
              <Col sm={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="to_section_divison"
                  label="Section/Division"
                  value={data?.toDeptDivSec}
                  shrink={true}
                />
              </Col>
              <Col sm={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="TO_FTE_Number"
                  label="FTE"
                  value={data?.toFteNo}
                  shrink={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ borderTop: "1px solid black" }}>
          {console.log(
            "123123 from list::: ",
            data?.nextIncrementDate,
            data?.worKSch,
            data
          )}
          <Col sm={4} className="p-1 border-dark border-end">
            Next Increment:
            <span className="p-4">
              {" "}
              {data?.nextIncrementDate
                ? moment(data?.nextIncrementDate).format("MM-DD-YYYY")
                : ""}{" "}
            </span>
            <br />
            Action No.:
            <span className="p-4"> {data?.actnNo} </span>
            <br />
            Work Schedule:
            <span className="p-4"> {data?.worKSch || ""} </span>
            <br />
          </Col>
          <Col sm={4} className="p-1 border-dark border-end">
            Exp/Separation Date:
            <span className="p-4">
              {" "}
              {data?.termDate
                ? moment(data?.termDate).format("MM-DD-YYYY")
                : ""}{" "}
            </span>
            <br />
            PMIS Action Date:
            <span className="p-4">
              {" "}
              {data?.actionDate
                ? moment(data?.actionDate).format("MM-DD-YYYY")
                : ""}{" "}
            </span>
            <br />
            Request ID:
            <span className="p-4"> {data?.requestId} </span>
            <br />
          </Col>
          <Col sm={4} className="p-1 border-dark">
            Bdgt Accn No.:
            <span className="p-4"> {data?.budgetAcctNo} </span>
            <br />
            JON:
            <span className="p-4"> {data?.jobOrderNumber} </span>
            <br />
          </Col>
        </Row>
        <Row style={{ borderTop: "1px solid black" }}>
          <Col sm={8} className="p-1 border-dark border-end">
            Retirement Type:
            <span className="p-4"> {data?.retirementCoverage || ""} </span>
            <br />
          </Col>
          <Col sm={4} className="p-1 border-dark">
            Personnel Jacket No.:
            <span className="p-4"> {data?.jacketNo || ""} </span>
            <br />
          </Col>
        </Row>

        <Row
          style={{
            borderTop: "1px solid black",
            minHeight: "22vh",
            display: "flex",
            flexWrap: "wrap",
            alignContent: "space-between",
          }}
        >
          <Col sm={12} className="p-1">
            14.REMARKS (continue in item 21,if necessary) <br />
            <span className="p-4">{data?.remarks}</span>
          </Col>

          <Col md={12} lg={12} sm={12} className="p-1">
            <Row
              style={{
                display: "flex",
                justifyContent: "end",
                width: "50%",
                float: "right",
                marginTop: "40px",
              }}
            >
              <Col sm={12} md={12} lg={12}>
                {/* <EmployeeSignature
                  signatureData={signatureData}
                  setSignatureData={setSignatureData}
                  label={""}
                /> */}
                <img
                  src={`${imagePath}/GuamSeal.png`}
                  // src={imagePath}
                  alt="Logo"
                  style={{
                    position: "absolute",
                    bottom: "85px",
                    right: "350px",
                    width: "47px", // Adjust width as needed
                    height: "auto", // Maintain aspect ratio
                  }}
                />
                <hr />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    marginLeft: "10px",
                  }}
                >
                  <B>For: APPOINTING AUTHORITY</B>
                  <B>Assisant Personnel Services Administrator,</B>
                  <B>HR Division</B>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
});
export default NpaPdf;
